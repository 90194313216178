
import { defineComponent, PropType } from 'vue'
import { TContact } from '@/types/TContact'

export default defineComponent({
    name: 'Customer',
    props: {
        customer: Object as PropType<TContact>
    }
})
