
import { defineComponent, PropType } from 'vue'
import { TContact } from '@/types/TContact'
import { TInvoiceHydrated } from '@/types/TInvoice'

export default defineComponent({
    name: 'PaymentInstructions',
    props: {
        invoice: Object as PropType<TInvoiceHydrated>,
        supplier: Object as PropType<TContact>
    }
})
