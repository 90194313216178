
import { computed, defineComponent, PropType, ref } from 'vue'
import { Form, Field } from 'vee-validate'
import { TContact } from '@/types/TContact'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { usePb } from '@/composables/usePb'
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'ContactForm',
    components: { SubmitButton, Form, Field },
    props: {
        contact: {
            required: false,
            type: Object as PropType<TContact>
        }
    },
    setup(props) {
        const { client } = usePb()
        const router = useRouter()

        const loading = ref(false)
        const companyId = ref(props.contact?.companyId)
        const btnAresEnabled = computed(() => companyId.value && regExp.test(companyId.value))

        const regExp = new RegExp(/^\d+$/)
        const schema = {
            email: 'email|required',
            name: 'required',
            country: 'required',
            companyId: 'integer'
        }

        const onSubmit = async (values: TContact) => {
            loading.value = true
            const data: TContact = {
                ...values,
                vatPayer: !! values?.vatPayer
            }

            try {
                if (props.contact?.id) {
                    await client.collection('contact').update(props.contact.id, data)
                    await router.push({ name: 'ContactDetail', params: { id: props.contact.id } })
                } else {
                    const resp = await client.collection('contact').create(data)
                    await router.push({ name: 'ContactDetail', params: { id: resp.id } })
                }
            } catch (e) {
                console.error(e)
            }

            loading.value = false
        }

        const handleFetchAres = async () => {
            //const uri = `https://wwwinfo.mfcr.cz/cgi-bin/ares/ares_es.cgi?ico=${companyId.value}`
            // TODO: request via CURL on PHP server
        }

        return { loading, onSubmit, schema, btnAresEnabled, companyId, handleFetchAres }
    }
})
