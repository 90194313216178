
import { defineComponent, PropType } from 'vue'
import { TContact } from '@/types/TContact'

export default defineComponent({
    name: 'ContactTable',
    props: {
        items: Array as PropType<Array<TContact>>
    }
})
