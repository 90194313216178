
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'SubmitButtonSm',
    emits: ['click'],
    props: {
        loading: Boolean,
        name: String,
        type: String
    },
    setup(props, { emit }) {
        const handleClick = () => {
            emit('click')
        }

        return { handleClick }
    }
})
