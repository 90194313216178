import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-4 d-flex justify-content-between align-content-end" }
const _hoisted_2 = { class: "mb-0" }
const _hoisted_3 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "buttons"),
      (_ctx.btnTitle)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.btnClick && _ctx.btnClick(...args))),
            class: "btn btn-success shadow-sm px-4 py-2"
          }, _toDisplayString(_ctx.btnTitle), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}