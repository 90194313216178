import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-light py-5 px-md-5 h-100 w-100 overflow-auto position-relative" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_LoadingOverlay = _resolveComponent("LoadingOverlay")!
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_ContactTable = _resolveComponent("ContactTable")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SideBar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LoadingOverlay, { loading: _ctx.loading }, null, 8, ["loading"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PageTitle, {
          title: "Kontakty",
          "btn-title": "Nový kontakt",
          btnClick: _ctx.onBtnClick
        }, null, 8, ["btnClick"]),
        _createVNode(_component_ContactTable, { items: _ctx.items }, null, 8, ["items"]),
        (_ctx.pagination)
          ? (_openBlock(), _createBlock(_component_Pagination, {
              key: 0,
              pagination: _ctx.pagination,
              "route-name": "Contacts"
            }, null, 8, ["pagination"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}