
import { defineComponent, PropType } from 'vue'
import { TInvoiceHydrated } from '@/types/TInvoice'
import { useProformaPrefix } from '@/composables/useProformaPrefix'

export default defineComponent({
    name: 'InvoiceTable',
    props: {
        items: Array as PropType<Array<TInvoiceHydrated>>
    },
    setup() {
        const { proformaPrefix } = useProformaPrefix()
        return { proformaPrefix }
    }
})
