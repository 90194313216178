
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'Dropdown',
    setup() {
        const show = ref(false)
        return { show }
    }
})
