
import { computed, defineComponent, onMounted, ref } from 'vue'
import { usePb } from '@/composables/usePb'
import { useRouter } from 'vue-router'
import { invoiceHydrator } from '@/hydrators/invoiceHydrator'
import { TInvoice, TInvoiceHydrated } from '@/types/TInvoice'
import { TContact } from '@/types/TContact'
import { TInvoiceItem, TInvoiceItemHydrated } from '@/types/TInvoiceItem'
import SideBar from '@/components/SideBar.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import Customer from '@/components/invoice/Customer.vue'
import PaymentInstructions from '@/components/invoice/PaymentInstructions.vue'
import PageTitle from '@/components/PageTitle.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import Supplier from '@/components/invoice/Supplier.vue'
import { TCompany } from '@/types/TCompany'
import Modal from '@/components/modal/Modal.vue'
import SubmitButtonSm from '@/components/buttons/SubmitButtonSm.vue'
import { Field, Form } from 'vee-validate'
import { timeHelper } from '@/helpers/timeHelper'
import { useProformaPrefix } from '@/composables/useProformaPrefix'

type FormValuesPaid = { paidDate: string }
type FormValuesCancel = { canceledDate: string }

export default defineComponent({
    name: 'InvoiceDetail',
    components: {
        SubmitButtonSm,
        Modal,
        Supplier,
        DropdownItem,
        Dropdown,
        PageTitle,
        PaymentInstructions,
        Customer,
        LoadingOverlay,
        SideBar,
        Form,
        Field
    },
    setup() {
        const { client } = usePb()
        const router = useRouter()
        const { hydrateInvoice, hydrateInvoiceItem } = invoiceHydrator()
        const { toCzechDateTimeString, czechDateTimeToObject } = timeHelper()
        const proforma = useProformaPrefix()

        const supplier = ref<TContact>()
        const customer = ref<TContact>()
        const company = ref<TCompany>()

        const invoice = ref<TInvoiceHydrated>()
        const invoiceItems = ref<TInvoiceItemHydrated[]>()
        const loading = ref(true)

        const modalBtnLoading = ref(false)

        const modalRemoveOpened = ref(false)
        const modalPaidOpened = ref(false)
        const modalCancelOpened = ref(false)

        const paidDate = ref<string>()
        const canceledDate = ref<string>()

        const proformaPrefix = computed(() => {
            return proforma.proformaPrefix(invoice.value?.proforma || false)
        })

        const handleEdit = () => {
            router.push({ name: 'InvoiceEdit', params: { id: invoice.value?.id } })
        }

        const handleRemove = async () => {
            modalBtnLoading.value = true
            await client.collection('invoice').delete(invoice.value?.id)
            await router.push({ name: 'Invoices', params: { page: 1 } })
            modalBtnLoading.value = false
        }

        const handleModalRemoveOpen = () => {
            modalRemoveOpened.value = true
        }

        const handleTogglePaid = async () => {
            if (invoice.value?.paid) {
                const resp: TInvoice = await client.collection('invoice').update(invoice.value?.id, {
                    paid: false,
                    paidDate: null
                })
                invoice.value = hydrateInvoice(resp)
            } else {
                modalPaidOpened.value = true
                paidDate.value = toCzechDateTimeString(new Date())
            }
        }

        const onSubmitPaid = async (values: FormValuesPaid) => {
            modalBtnLoading.value = true
            const resp: TInvoice = await client.collection('invoice').update(invoice.value?.id, {
                paid: true,
                paidDate: czechDateTimeToObject(values.paidDate).toISOString()
            }, {
                expand: 'customer,company.contact'
            })
            invoice.value = hydrateInvoice(resp)
            modalBtnLoading.value = false
            modalPaidOpened.value = false
        }

        const handleToggleCanceled = async () => {
            if (invoice.value?.canceled) {
                const resp: TInvoice = await client.collection('invoice').update(invoice.value?.id, {
                    canceled: false,
                    canceledDate: null
                }, {
                    expand: 'customer,company.contact'
                })
                invoice.value = hydrateInvoice(resp)
            } else {
                modalCancelOpened.value = true
                canceledDate.value = toCzechDateTimeString(new Date())
            }
        }

        const onSubmitCancel = async (values: FormValuesCancel) => {
            modalBtnLoading.value = true
            const resp: TInvoice = await client.collection('invoice').update(invoice.value?.id, {
                canceled: true,
                canceledDate: czechDateTimeToObject(values.canceledDate).toISOString()
            })
            invoice.value = hydrateInvoice(resp)
            modalBtnLoading.value = false
            modalCancelOpened.value = false
        }

        const handleShowWebInvoice = () => {
            router.push({ name: 'InvoiceDetailPublic', params: { id: invoice.value?.id } })
        }

        const handleGeneratePdf = () => {

        }

        const handleSendMail = () => {

        }

        onMounted(async () => {
            const respInvoice = await client.collection('invoice').getOne(router.currentRoute.value.params.id, {
                expand: 'customer,company.contact'
            })

            invoice.value = hydrateInvoice(respInvoice)
            customer.value = invoice.value?.expand.customer
            company.value = invoice.value?.expand.company
            supplier.value = invoice.value?.expand.company.expand.contact

            const resp = await client.collection('invoice_item').getList(1, 400, {
                filter: `invoice='${respInvoice.id}'`,
                sort: 'position'
            })

            const respInvoiceItems = resp.items as TInvoiceItem[]

            invoiceItems.value = respInvoiceItems.map(item => hydrateInvoiceItem(item, invoice.value?.currency as string))

            loading.value = false
        })

        return {
            invoice,
            invoiceItems,
            supplier,
            customer,
            company,
            loading,
            proformaPrefix,

            modalBtnLoading,
            modalRemoveOpened,
            handleRemove,

            modalPaidOpened,
            onSubmitPaid,
            handleTogglePaid,
            paidDate,

            modalCancelOpened,
            onSubmitCancel,
            handleToggleCanceled,
            canceledDate,

            handleEdit,
            handleModalRemoveOpen,

            handleGeneratePdf,
            handleShowWebInvoice,
            handleSendMail
        }
    }
})
