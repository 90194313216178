
import { defineComponent, ref } from 'vue'
import { Field } from 'vee-validate'

export default defineComponent({
    name: 'Autocomplete',
    emits: ['onSelect'],
    components: { Field },
    props: {
        label: String,
        name: String,
        errors: Object,
        searchHandler: Function,
        labelWrapper: String,
        inputWrapper: String
    },
    setup(props, { emit }) {

        const inputName = ref()
        const items = ref([])
        const selectedObject = ref()
        const selectedId = ref()

        const onSelect = (item: any) => {
            selectedObject.value = item
            selectedId.value = item.id

            emit('onSelect', selectedId.value, selectedObject.value)
        }

        const onSearch = async () => {
            selectedObject.value = undefined
            selectedId.value = undefined

            if (inputName.value?.length > 1) {
                items.value = await (props.searchHandler as Function)(inputName.value.trim())
            } else {
                items.value = []
            }
        }

        const handleHide = () => {
            if (selectedObject.value) {
                inputName.value = selectedObject.value?.name
            }

            items.value = []
        }

        return {
            items,
            selectedObject,
            selectedId,
            onSelect,
            onSearch,
            handleHide,
            inputName
        }
    }
})
