
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import SideBar from '@/components/SideBar.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import PageTitle from '@/components/PageTitle.vue'
import { useRouter } from 'vue-router'
import ContactTable from '@/components/tables/ContactTable.vue'
import { usePb } from '@/composables/usePb'
import { TPagination } from '@/types/TPagination'
import Pagination from '@/components/pagination/Pagination.vue'
import { TContact } from '@/types/TContact'

export default defineComponent({
    name: 'Costs',
    components: { Pagination, ContactTable, PageTitle, LoadingOverlay, SideBar },

    setup() {
        const router = useRouter()
        const { client, hydratePagination } = usePb()

        const loading = ref(true)
        const items = ref<TContact[]>([])
        const pagination = ref<TPagination>()
        const page = computed(() => router.currentRoute.value.params.page)

        const onBtnClick = () => {
            router.push({ name: 'ContactAdd' })
        }

        const load = async () => {
            const resContacts = await client.collection('contact').getList(page.value, 15, {
                sort: 'name',
            })

            items.value = resContacts.items
            pagination.value = hydratePagination(resContacts)

            loading.value = false
        }

        watch(page, () => load())
        onMounted(() => load())

        return { loading, items, pagination, onBtnClick }
    }
})
