
import { Field, Form } from 'vee-validate'
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { usePb } from '@/composables/usePb'
import Alert from '@/components/Alert.vue'
import { TAlert } from '@/types/TAlert'
import { TCompany } from '@/types/TCompany'
import { useCompanyStore } from '@/components/store/companyStore'

type Values = {
    email: string,
    password: string
}

export default defineComponent({
    name: 'LoginForm',
    components: {
        Alert,
        SubmitButton,
        Form,
        Field
    },
    setup() {
        const router = useRouter()
        const companyStore = useCompanyStore()
        const { client } = usePb()

        const loading = ref(false)
        const alert = ref<TAlert>()

        const schema = {
            email: 'required|email',
            password: 'required'
        }

        const onSubmit = async (values: Values) => {
            loading.value = true

            try {
                await client.collection('users').authWithPassword(values.email, values.password)

                const company: TCompany = (await client.collection('company').getList(1, 400, {
                    filter: 'primary=true',
                    expand: 'contact'
                })).items.shift()

                companyStore.setCurrent(company)
                await router.push({ name: 'Invoices', params: { page: 1 } })

            } catch (e) {
                console.error(e)
                alert.value = { type: 'alert-danger', message: 'Nepodařilo se přihlásit' }
            }

            loading.value = false
        }

        return { loading, alert, onSubmit, schema }
    }
})
