
import { defineComponent, PropType } from 'vue'
import { TInvoiceItem } from '@/types/TInvoiceItem'
import { Field, Form } from 'vee-validate'

export default defineComponent({
    name: 'InvoiceItemField',
    components: {
        Form,
        Field
    },
    props: {
        first: Boolean,
        last: Boolean,
        count: Number,
        errors: Object,
        item: Object as PropType<TInvoiceItem>
    },
    emits: ['onRemove', 'onMove'],
    setup(props, { emit }) {

        const handleRemove = () => {
            emit('onRemove', props.item?.fieldUid)
        }

        const handleMove = (direction: 'UP' | 'DOWN') => {
            emit('onMove', props.item?.fieldUid, direction)
        }

        return { handleRemove, handleMove }
    }
})
