
import { defineComponent, PropType } from 'vue'
import { TAlert } from '@/types/TAlert'

export default defineComponent({
    name: 'Alert',
    props: {
        data: Object as PropType<TAlert | undefined>
    }
})
