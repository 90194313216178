
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
    name: 'Stats',
    components: {},
    setup() {
        onMounted(async () => {

        })
    }
})
