import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-light py-5 px-md-5 h-100 w-100 overflow-auto position-relative" }
const _hoisted_2 = {
  key: 0,
  class: "container"
}
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_LoadingOverlay = _resolveComponent("LoadingOverlay")!
  const _component_InvoiceForm = _resolveComponent("InvoiceForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SideBar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LoadingOverlay, { loading: _ctx.loading }, null, 8, ["loading"]),
      (_ctx.nextInvoiceNumber && _ctx.invoice && _ctx.invoiceItems)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.invoice?.proforma ? 'Proforma faktura' : 'Faktura') + " " + _toDisplayString(_ctx.proformaPrefix) + _toDisplayString(_ctx.invoice?.invoiceNumberPretty), 1),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_InvoiceForm, {
                invoice: _ctx.invoice,
                items: _ctx.invoiceItems,
                "next-invoice-number": _ctx.nextInvoiceNumber
              }, null, 8, ["invoice", "items", "next-invoice-number"])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}