
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'DropdownItem',
    props: {
        name: String,
        classes: String
    },
    emits: ['click'],
    setup(props, { emit }) {
        const show = ref(false)
        const click = () => emit('click')
        return { show, click }
    }
})
