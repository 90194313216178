
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useCompanyStore } from '@/components/store/companyStore'
import { usePb } from '@/composables/usePb'
import { TInvoice, TInvoiceHydrated } from '@/types/TInvoice'
import { TInvoiceItem } from '@/types/TInvoiceItem'
import { invoiceHelper } from '@/helpers/invoiceHelper'
import { useRouter } from 'vue-router'
import { invoiceHydrator } from '@/hydrators/invoiceHydrator'
import SideBar from '@/components/SideBar.vue'
import PageTitle from '@/components/PageTitle.vue'
import ContactForm from '@/components/forms/ContactForm.vue'
import InvoiceForm from '@/components/forms/InvoiceForm.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import { useProformaPrefix } from '@/composables/useProformaPrefix'

export default defineComponent({
    name: 'ContactAdd',
    components: { LoadingOverlay, InvoiceForm, ContactForm, PageTitle, SideBar },
    setup() {
        const { client } = usePb()
        const router = useRouter()
        const companyStore = useCompanyStore()
        const proforma = useProformaPrefix()
        const { hydrateInvoice } = invoiceHydrator()
        const { makeFirstInvoiceNumberInYear } = invoiceHelper()

        const invoice = ref<TInvoiceHydrated>()
        const invoiceItems = ref<TInvoiceItem>()
        const nextInvoiceNumber = ref<number>()
        const loading = ref(true)

        const id = router.currentRoute.value.params.id

        const proformaPrefix = computed(() => {
            return proforma.proformaPrefix(invoice.value?.proforma || false)
        })

        onMounted(async () => {
            const resp1 = await client.collection('invoice').getList(1, 1, {
                filter: `company='${companyStore.current?.id}'`,
                sort: '-invoiceNumber'
            })
            const data = resp1.items.shift() as TInvoice | null
            nextInvoiceNumber.value = data?.invoiceNumber ? ++data.invoiceNumber : makeFirstInvoiceNumberInYear()

            const resp2 = await client.collection('invoice').getOne(id, { expand: 'customer,company.contact' })
            invoice.value = hydrateInvoice(resp2)

            const resp3 = await client.collection('invoice_item').getList(1, 400, {
                filter: `invoice='${resp2.id}'`,
                sort: 'position'
            })
            invoiceItems.value = resp3.items

            loading.value = false
        })


        return { invoice, invoiceItems, nextInvoiceNumber, loading, proformaPrefix }
    }
})
