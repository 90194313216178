
import { defineComponent } from 'vue'
import { usePb } from '@/composables/usePb'
import { useRouter } from 'vue-router'
import ChangeCompany from '@/components/ChangeCompany.vue'

export default defineComponent({
    name: 'SideBar',
    components: { ChangeCompany },
    setup() {
        const router = useRouter()
        const { client } = usePb()

        const logout = async () => {
            await client.authStore.clear()
            await router.push({ name: 'Login' })
        }

        return { logout }
    }
})
