
import { computed, defineComponent, PropType, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Form, Field } from 'vee-validate'
import { usePb } from '@/composables/usePb'
import { useCompanyStore } from '@/components/store/companyStore'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import Autocomplete from '@/components/autocomplete/Autocomplete.vue'
import InvoiceItemField from '@/components/forms/InvoiceItemField.vue'
import { invoiceHelper } from '@/helpers/invoiceHelper'
import { timeHelper } from '@/helpers/timeHelper'
import { TInvoice, TInvoiceHydrated } from '@/types/TInvoice'
import { TInvoiceItem } from '@/types/TInvoiceItem'
import * as yup from 'yup'
import { priceHelper } from '@/helpers/priceHelper'
import { useProformaPrefix } from '@/composables/useProformaPrefix'

export default defineComponent({
    name: 'InvoiceForm',
    components: { InvoiceItemField, Autocomplete, SubmitButton, Form, Field },
    props: {
        invoice: Object as PropType<TInvoiceHydrated>,
        items: Array as PropType<TInvoiceItem[]>,
        nextInvoiceNumber: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const { client } = usePb()
        const router = useRouter()
        const companyStore = useCompanyStore()
        const proforma = useProformaPrefix()
        const { makePrettyNumber, makeSuggestedInvoiceNumber } = invoiceHelper()
        const { toCzechDateString, isValidCzechDate, czechDateToObject } = timeHelper()
        const { toFormattedPrice } = priceHelper()

        let fieldId = 0
        const createItem = (position: number | null, item?: TInvoiceItem): TInvoiceItem => {
            if (item) {
                return { ...item, fieldUid: 'uid-' + (++fieldId).toString() }
            }
            return { fieldUid: 'uid-' + (++fieldId).toString(), position: position as number }
        }

        const sumPrice = (items: TInvoiceItem[]): number => {
            return items ? Object.values(items).reduce((accumulator, item) => {
                const sumPrice = (item.price || 0) * (item.quantity || 0)
                return accumulator + (isNaN(sumPrice) ? 0 : sumPrice)
            }, 0) : 0
        }

        const dueDays = 14
        const future = new Date()
        future.setDate(future.getDate() + dueDays)

        const loading = ref<boolean>(false)
        const isProforma = ref(props.invoice?.proforma || false)
        const invoiceNumber = ref<number | undefined>(props.invoice?.invoiceNumber || props.nextInvoiceNumber)
        const invoiceItems = ref<Array<TInvoiceItem>>(props.items ? props.items.map(item => createItem(null, item)) : [createItem(1)])
        const issueDate = ref(props.invoice?.issueDate || toCzechDateString(new Date()))
        const dueDate = ref(props.invoice?.dueDate || toCzechDateString(future))

        const schema = computed(() => {
            yup.setLocale({
                mixed: {
                    notType: 'zadejte číselnou hodnotu'
                }
            })
            return yup.object({
                customer: yup.string().required(),
                invoiceNumber: yup.number().required().test('minMaxLength', 'zadejte 5 až 10 znaků', (val: any) => {
                    if (val) {
                        const value = val.toString()
                        return value.length > 4 && value.length < 11
                    }
                    return true
                }),
                paymentType: yup.string().required(),
                currency: yup.string().required(),
                issueDate: yup.string().czechDate().required(),
                dueDate: yup.string().czechDate().required(),
                items: yup.object().shape(schemaItems.value)
            })
        })

        const schemaItems = computed(() => {
            const rules = {}
            yup.setLocale({
                mixed: {
                    notType: 'zadejte číslo'
                }
            })
            invoiceItems.value.map(item => item.fieldUid).forEach(idx => {
                //@ts-ignore
                rules[idx] = yup.object({
                    quantity: yup.number().required('zadejte počet'),
                    measureUnit: yup.string(),
                    price: yup.number().required('zadejte cenu'),
                    description: yup.string().required('zadejte popis položky')
                })
            })
            return rules
        })

        const proformaPrefix = computed(() => {
            return proforma.proformaPrefix(isProforma.value || false)
        })

        const nextInvoiceNumberPretty = computed(() => {
            if (invoiceNumber.value) {
                return makePrettyNumber(invoiceNumber.value)
            }
        })

        const suggestedInvoiceNumber = computed<number | undefined>(() => {
            if (invoiceNumber.value) {
                const invNum = parseInt(invoiceNumber.value.toString())
                const suggested = makeSuggestedInvoiceNumber(invNum, props.nextInvoiceNumber)
                return suggested === 0 ? undefined : suggested
            }
        })

        const suggestedInvoiceNumberPretty = computed(() => {
            return suggestedInvoiceNumber.value ? makePrettyNumber(suggestedInvoiceNumber.value) : null
        })

        const handleNewCustomer = () => {
            router.push({ name: 'ContactAdd' })
        }

        const handleNewInvoiceNumber = () => {
            invoiceNumber.value = suggestedInvoiceNumber.value as number
        }

        const handleIssueDateChanged = () => {
            if (isValidCzechDate(issueDate.value)) {
                const due = czechDateToObject(issueDate.value)
                due.setDate(due.getDate() + dueDays)
                dueDate.value = toCzechDateString(due)
            }
        }

        const handleItemAdd = () => {
            const nextPos = Math.max(...invoiceItems.value.map(item => item.position)) + 1
            invoiceItems.value.push(createItem(nextPos))
        }

        const handleItemRemove = (fieldUid: string) => {
            let count = 0
            invoiceItems.value = invoiceItems.value.filter(item => item.fieldUid !== fieldUid).map(item => {
                return { ...item, position: ++count }
            })
        }

        const handleItemMove = (fieldUid: string, direction: 'UP' | 'DOWN') => {
            const items = invoiceItems.value
            const idx = items.map(item => item.fieldUid).indexOf(fieldUid)
            const current = items[idx]

            if (direction === 'UP') {
                const oneUp = items[idx - 1]
                items[idx - 1] = { ...current, position: idx }
                items[idx] = { ...oneUp, position: idx + 1 }
            } else {
                const oneDown = items[idx + 1]
                items[idx + 1] = { ...current, position: idx + 2 }
                items[idx] = { ...oneDown, position: idx + 1 }
            }

            invoiceItems.value = items
        }

        const searchHandler = async (name: string) => {
            try {
                const resp = await client.collection('contact').getList(1, 10, {
                    filter: `name~'${name}'`
                })
                return resp.items
            } catch (e) {
            }

            return []
        }

        const onSubmit = async (values: TInvoice) => {
            loading.value = true

            const items: TInvoiceItem[] = invoiceItems.value.map(item => (values as any).items[item.fieldUid])

            type TData = { items?: Array<TInvoiceItem> }
            const data: TInvoice & TData = {
                ...values,
                company: companyStore.current?.id as string,
                issueDate: czechDateToObject(values.issueDate).toISOString(),
                dueDate: czechDateToObject(values.dueDate).toISOString(),
                items: undefined,
                sumPrice: sumPrice(items),
                proforma: !! values?.proforma
            }

            try {
                if (! props.invoice?.id) {
                    const resp = await client.collection('invoice').create(data)
                    const itemsData = items.map(item => {
                        return {
                            ...item,
                            invoice: resp.id
                        }
                    }) as TInvoiceItem[]

                    for (const item of itemsData) {
                        await client.collection('invoice_item').create(item)
                    }

                    await router.push({ name: 'InvoiceDetail', params: { id: resp.id } })

                } else {
                    const resp = await client.collection('invoice').update(props.invoice.id, data)
                    const itemsData = items.map(item => {
                        return {
                            ...item,
                            invoice: resp.id
                        }
                    }) as TInvoiceItem[]

                    const resp2 = await client.collection('invoice_item').getList(1, 400, {
                        filter: `invoice='${resp.id}'`
                    })

                    for (const item of resp2.items) {
                        await client.collection('invoice_item').delete(item.id)
                    }

                    for (const item of itemsData) {
                        await client.collection('invoice_item').create(item)
                    }

                    await router.push({ name: 'InvoiceDetail', params: { id: resp.id } })
                }
            } catch (e) {
                console.error(e)
            }

            loading.value = false
        }

        return {
            schema,
            loading,
            onSubmit,
            sumPrice,
            toFormattedPrice,

            proformaPrefix,
            isProforma,

            invoiceItems,
            handleItemAdd,
            handleItemRemove,
            handleItemMove,

            issueDate,
            dueDate,
            invoiceNumber,
            nextInvoiceNumberPretty,
            suggestedInvoiceNumberPretty,

            handleNewInvoiceNumber,
            handleIssueDateChanged,
            handleNewCustomer,
            searchHandler
        }
    }
})
