import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("select", {
    onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
    disabled: _ctx.loading,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.input) = $event)),
    class: "form-select form-select-sm",
    "aria-label": ".form-select-sm example"
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companies, (company) => {
      return (_openBlock(), _createElementBlock("option", {
        value: company.id
      }, _toDisplayString(company.expand.contact?.name), 9, _hoisted_2))
    }), 256))
  ], 40, _hoisted_1)), [
    [_vModelSelect, _ctx.input]
  ])
}