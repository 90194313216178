
import { computed, defineComponent, onMounted, ref } from 'vue'
import { usePb } from '@/composables/usePb'
import { useRouter } from 'vue-router'
import { invoiceHydrator } from '@/hydrators/invoiceHydrator'
import { TInvoiceHydrated } from '@/types/TInvoice'
import { TContact } from '@/types/TContact'
import { TInvoiceItem, TInvoiceItemHydrated } from '@/types/TInvoiceItem'
import { TCompany } from '@/types/TCompany'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import Customer from '@/components/invoice/Customer.vue'
import PaymentInstructions from '@/components/invoice/PaymentInstructions.vue'
import Supplier from '@/components/invoice/Supplier.vue'
import { useProformaPrefix } from '@/composables/useProformaPrefix'
import SubmitButtonSm from '@/components/buttons/SubmitButtonSm.vue'
import { invoiceManager } from '@/managers/invoiceManager'

export default defineComponent({
    name: 'InvoiceDetailPublic',
    components: {
        SubmitButtonSm,
        Supplier,
        PaymentInstructions,
        Customer,
        LoadingOverlay
    },
    setup() {
        const { client } = usePb()
        const router = useRouter()
        const proforma = useProformaPrefix()
        const { hydrateInvoice, hydrateInvoiceItem } = invoiceHydrator()
        const { downloadPdf } = invoiceManager()

        const loggedIn = ref(client.authStore?.model)
        const supplier = ref<TContact>()
        const customer = ref<TContact>()
        const company = ref<TCompany>()

        const invoice = ref<TInvoiceHydrated>()
        const invoiceItems = ref<TInvoiceItemHydrated[]>()
        const loading = ref(true)

        const downloading = ref(false)

        const proformaPrefix = computed(() => {
            return proforma.proformaPrefix(invoice.value?.proforma || false)
        })

        const qrCodeUrl = computed(() => {
            if (invoice.value?.paymentType === 'Převodem') {
                const [accountNumber, bankCode] = (invoice.value?.expand.company.expand.contact?.bankAccount as string).replaceAll(' ', '').split('/')

                const params = {
                    accountNumber,
                    bankCode,
                    amount: invoice.value?.sumPrice,
                    currency: invoice.value?.currency,
                    vs: invoice.value?.variableSymbol,
                    message: 'QR Platba',
                    size: 200
                }

                //@ts-ignore
                const queryParams = Object.keys(params).map(key => `${key}=${encodeURIComponent(params[key])}`).join('&')

                return 'https://api.paylibo.com/paylibo/generator/czech/image?' + queryParams
            }

            return null
        })

        const handleDownload = async () => {
            downloading.value = true

            if (invoice.value && invoiceItems.value) {
                const data = await downloadPdf(invoice.value, invoiceItems.value)
                //window.open(data.pdf_url, '_blank')
                window.location.href = data.pdf_url
            }

            downloading.value = false
        }

        onMounted(async () => {
            try {
                const resp1 = await client.collection('invoice').getOne( router.currentRoute.value.params.id, {
                    expand: 'customer,company.contact'
                })

                const resp2 = await client.collection('invoice_item').getList(1, 400, {
                    filter: `invoice='${resp1.id}'`,
                    sort: 'position'
                })

                const respInvoiceItems = resp2.items as TInvoiceItem[]

                invoice.value = hydrateInvoice(resp1)
                customer.value = invoice.value?.expand.customer
                company.value = invoice.value?.expand.company
                supplier.value = invoice.value?.expand.company.expand.contact
                invoiceItems.value = respInvoiceItems.map(item => hydrateInvoiceItem(item, invoice.value?.currency as string))
            } catch (e) {
                console.error(e)
            }

            loading.value = false
        })

        return {
            invoice,
            invoiceItems,
            supplier,
            customer,
            company,
            loading,
            proformaPrefix,
            loggedIn,
            qrCodeUrl,
            downloading,
            handleDownload
        }
    }
})
