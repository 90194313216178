
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'SubmitButton',
    props: {
        btnTitle: String,
        classes: String,
        loading: Boolean
    }
})
