
import { defineComponent, onMounted, ref } from 'vue'
import { useCompanyStore } from '@/components/store/companyStore'
import { usePb } from '@/composables/usePb'
import { TInvoice } from '@/types/TInvoice'
import { invoiceHelper } from '@/helpers/invoiceHelper'
import SideBar from '@/components/SideBar.vue'
import PageTitle from '@/components/PageTitle.vue'
import ContactForm from '@/components/forms/ContactForm.vue'
import InvoiceForm from '@/components/forms/InvoiceForm.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'

export default defineComponent({
    name: 'ContactAdd',
    components: { LoadingOverlay, InvoiceForm, ContactForm, PageTitle, SideBar },
    setup() {
        const { client } = usePb()
        const companyStore = useCompanyStore()
        const { makeFirstInvoiceNumberInYear } = invoiceHelper()

        const nextInvoiceNumber = ref<number>()
        const loading = ref(true)

        onMounted(async () => {
            const resp = await client.collection('invoice').getList(1, 1, {
                filter: `company='${companyStore.current?.id}'`,
                sort: '-invoiceNumber'
            })

            const data = resp.items.shift() as TInvoice | null
            nextInvoiceNumber.value = data?.invoiceNumber ? ++data.invoiceNumber : makeFirstInvoiceNumberInYear()

            loading.value = false
        })

        return { nextInvoiceNumber, loading }
    }
})
