import { createApp } from 'vue'
import { defineRule } from 'vee-validate'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import AllRules from '@vee-validate/rules'
import '@/validators/validators'
import '@/assets/scss/bootstrap.scss'

Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule])
})

const pinia = createPinia()
createApp(App).use(router).use(pinia).mount('#app')