
import { defineComponent } from 'vue'
import SideBar from '@/components/SideBar.vue'
import PageTitle from '@/components/PageTitle.vue'
import ContactForm from '@/components/forms/ContactForm.vue'

export default defineComponent({
    name: 'ContactDetail',
    components: { ContactForm, PageTitle, SideBar },
})
