
import { defineComponent, PropType, ref } from 'vue'
import { useRouter } from 'vue-router'
import { usePb } from '@/composables/usePb'
import { TCompany } from '@/types/TCompany'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import Modal from '@/components/modal/Modal.vue'

export default defineComponent({
    name: 'Company',
    components: { Modal, Dropdown, DropdownItem },
    props: {
        company: Object as PropType<TCompany>
    },
    emits: ['onRemoved', 'onChangedPrimary'],
    setup(props, { emit }) {
        const router = useRouter()
        const { client } = usePb()

        const modalRemoveOpened = ref(false)
        const modalBtnLoading = ref(false)

        const handleShowContact = () => {
            router.push({ name: 'ContactDetail', params: { id: props.company?.expand.contact?.id } })
        }

        const handleEdit = () => {
            router.push({ name: 'CompanyEdit', params: { id: props.company?.id } })
        }

        const handleMakePrimary = async () => {
            await client.collection('company').update(props.company?.id, { primary: true })
            emit('onChangedPrimary', props.company)
        }

        const handleRemove = async () => {
            modalBtnLoading.value = true
            await client.collection('company').delete(props.company?.id)

            modalBtnLoading.value = false
            modalRemoveOpened.value = false

            emit('onRemoved', props.company?.id)
        }

        return {
            handleEdit,
            handleShowContact,
            handleMakePrimary,
            handleRemove,

            modalRemoveOpened,
            modalBtnLoading
        }
    }
})
