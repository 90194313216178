import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { usePb } from '@/composables/usePb'
import Login from '@/views/Login.vue'
import Invoices from '@/views/Invoices.vue'
import ScheduledInvoices from '@/views/ScheduledInvoices.vue'
import Costs from '@/views/Costs.vue'
import Contacts from '@/views/Contacts.vue'
import Companies from '@/views/Companies.vue'
import InvoiceDetail from '@/views/InvoiceDetail.vue'
import ContactDetail from '@/views/ContactDetail.vue'
import ContactAdd from '@/views/ContactAdd.vue'
import ContactEdit from '@/views/ContactEdit.vue'
import CompanyEdit from '@/views/CompanyEdit.vue'
import InvoiceAdd from '@/views/InvoiceAdd.vue'
import InvoiceEdit from '@/views/InvoiceEdit.vue'
import InvoiceDetailPublic from '@/views/InvoiceDetailPublic.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/invoices/:page',
        name: 'Invoices',
        component: Invoices
    },
    {
        path: '/invoice/:id',
        name: 'InvoiceDetail',
        component: InvoiceDetail
    },
    {
        path: '/invoice/public/:id',
        name: 'InvoiceDetailPublic',
        component: InvoiceDetailPublic
    },
    {
        path: '/invoice/:id/edit',
        name: 'InvoiceEdit',
        component: InvoiceEdit
    },
    {
        path: '/invoice/add',
        name: 'InvoiceAdd',
        component: InvoiceAdd
    },
    {
        path: '/scheduled-invoices',
        name: 'ScheduledInvoices',
        component: ScheduledInvoices
    },
    {
        path: '/costs',
        name: 'Costs',
        component: Costs
    },
    {
        path: '/contacts/:page',
        name: 'Contacts',
        component: Contacts
    },
    {
        path: '/contacts/add',
        name: 'ContactAdd',
        component: ContactAdd
    },
    {
        path: '/contact/:id/edit',
        name: 'ContactEdit',
        component: ContactEdit
    },
    {
        path: '/contact/:id',
        name: 'ContactDetail',
        component: ContactDetail
    },
    {
        path: '/companies',
        name: 'Companies',
        component: Companies
    },
    {
        path: '/company/:id/edit',
        name: 'CompanyEdit',
        component: CompanyEdit
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const { client } = usePb()

    if (to.name === 'InvoiceDetailPublic') {
        next()
    } else if (to.name === 'InvoiceDetail' && ! client.authStore.model) {
        next({ name: 'InvoiceDetailPublic', params: { id: to.params.id } })
    } else if (! client.authStore.model && to.name !== 'Login') {
        next({ name: 'Login' })
    } else if (client.authStore.model && to.name === 'Login') {
        next({ name: 'Invoices', params: { page: 1 } })
    } else {
        next()
    }
})

export default router
