import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "table-responsive" }
const _hoisted_2 = { class: "table table-hover text-nowrap" }
const _hoisted_3 = { class: "p-3" }
const _hoisted_4 = { class: "py-3" }
const _hoisted_5 = { class: "py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("tr", null, [
            _createElementVNode("td", _hoisted_3, [
              _createVNode(_component_router_link, {
                to: { name: 'ContactDetail', params: { id: item.id} }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _createElementVNode("td", _hoisted_4, _toDisplayString(item.email), 1),
            _createElementVNode("td", _hoisted_5, _toDisplayString(item.vatPayer ? 'Plátce DPH' : ''), 1)
          ]))
        }), 256))
      ])
    ])
  ]))
}