
import { defineComponent, onMounted, ref } from 'vue'
import SideBar from '@/components/SideBar.vue'
import { useRouter } from 'vue-router'
import PageTitle from '@/components/PageTitle.vue'
import ContactForm from '@/components/forms/ContactForm.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import { usePb } from '@/composables/usePb'
import { TContact } from '@/types/TContact'
import Modal from '@/components/modal/Modal.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import { TAlert } from '@/types/TAlert'

export default defineComponent({
    name: 'ContactDetail',
    components: { DropdownItem, Dropdown, Modal, LoadingOverlay, ContactForm, PageTitle, SideBar },
    setup() {
        const router = useRouter()
        const { client } = usePb()

        const loading = ref(true)
        const contact = ref<TContact>()

        const modalRemove = ref(false)
        const modalLoading = ref(false)
        const modalAlert = ref<TAlert>()

        const id = router.currentRoute.value.params.id

        const handleEdit = () => {
            router.push({ name: 'ContactEdit', params: { id } })
        }

        const handleRemove = async () => {
            modalLoading.value = true

            try {
                await client.collection('contact').delete(id)
                await router.push({ name: 'Contacts', params: { page: 1 } })
            } catch (e) {
                modalAlert.value = { type: 'alert-danger', message: 'Kontakt nelze odstranit, jelikož je provázán s jinými položkami (faktury, firmy, náklady, atp.).' }
            }

            modalLoading.value = false
        }

        const handleModalRemoveOpen = () => {
            modalRemove.value = true
            modalAlert.value = undefined
        }

        onMounted(async () => {
            try {
                contact.value = await client.collection('contact').getOne(id)
            } catch (e) {
                console.error(e)
            }

            loading.value = false
        })

        return {
            loading,
            contact,
            handleEdit,
            handleRemove,
            modalRemove,
            modalLoading,
            modalAlert,
            handleModalRemoveOpen
        }
    }
})
