
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'PageTitle',
    components: {},
    props: {
        title: String,
        btnTitle: String,
        btnClick: {
            type: Function,
            required: false
        },
    },
    setup() {
        const show = ref(false)

        return { show }
    }
})
