import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fw-bold text-nowrap" }
const _hoisted_2 = { class: "h6 text-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, { to: "#modals" }, [
      (_ctx.modalRemoveOpened)
        ? (_openBlock(), _createBlock(_component_Modal, {
            key: 0,
            title: "Odstranit firmu",
            "btn-accept": "Ano, odstranit",
            "accept-loading": _ctx.modalBtnLoading,
            onAccept: _ctx.handleRemove,
            onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalRemoveOpened = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Opravdu si přejete odstranit firmu "),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.company.expand.contact.name), 1),
              _createTextVNode("? ")
            ]),
            _: 1
          }, 8, ["accept-loading", "onAccept"]))
        : _createCommentVNode("", true)
    ])),
    _createElementVNode("div", {
      class: _normalizeClass(["shadow-sm rounded-3 p-4 bg-white d-flex justify-content-between border", { 'border-secondary' : _ctx.company.primary, 'border-white' : !_ctx.company.primary }])
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.company.expand.contact.name), 1),
        _createElementVNode("div", null, "IČO: " + _toDisplayString(_ctx.company.expand.contact.companyId), 1)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_Dropdown, null, {
          default: _withCtx(() => [
            _createVNode(_component_DropdownItem, {
              onClick: _ctx.handleEdit,
              name: "Nastavení firmy"
            }, null, 8, ["onClick"]),
            _createVNode(_component_DropdownItem, {
              onClick: _ctx.handleShowContact,
              name: "Zobrazit kontakt"
            }, null, 8, ["onClick"]),
            (!_ctx.company.primary)
              ? (_openBlock(), _createBlock(_component_DropdownItem, {
                  key: 0,
                  onClick: _ctx.handleMakePrimary,
                  name: "Nastavit jako výchozí"
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (!_ctx.company.primary)
              ? (_openBlock(), _createBlock(_component_DropdownItem, {
                  key: 1,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modalRemoveOpened = true)),
                  name: "Trvale odstranit",
                  classes: "text-danger"
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ], 2)
  ], 64))
}