
import { defineComponent, PropType, ref } from 'vue'
import { Form, Field } from 'vee-validate'
import { TContact } from '@/types/TContact'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { usePb } from '@/composables/usePb'
import { useRouter } from 'vue-router'
import { TCompany } from '@/types/TCompany'
import { useCompanyStore } from '@/components/store/companyStore'

export default defineComponent({
    name: 'CompanyForm',
    components: { SubmitButton, Form, Field },
    props: {
        company: Object as PropType<TCompany>
    },
    setup(props) {
        const { client } = usePb()
        const router = useRouter()
        const companyStore = useCompanyStore()

        const loading = ref(false)

        const onSubmit = async (values: TContact) => {
            loading.value = true

            try {
                const resp = await client.collection('company').update(props.company?.id, values, {
                    expand: 'contact'
                })

                if (companyStore.current?.id === resp.id) {
                    companyStore.setCurrent(resp)
                }

                await router.push({ name: 'Companies' })
            } catch (e) {
                console.error(e)
            }

            loading.value = false
        }

        return { loading, onSubmit }
    }
})
