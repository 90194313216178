
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import SideBar from '@/components/SideBar.vue'
import InvoiceTable from '@/components/tables/InvoiceTable.vue'
import Pagination from '@/components/pagination/Pagination.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import PageTitle from '@/components/PageTitle.vue'
import Stats from '@/components/invoice/Stats.vue'
import { usePb } from '@/composables/usePb'
import { TInvoice, TInvoiceHydrated } from '@/types/TInvoice'
import { TPagination } from '@/types/TPagination'
import { useRouter } from 'vue-router'
import { useCompanyStore } from '@/components/store/companyStore'
import { invoiceHydrator } from '@/hydrators/invoiceHydrator'

export default defineComponent({
    name: 'Invoices',
    components: { Stats, PageTitle, LoadingOverlay, Pagination, InvoiceTable, SideBar },
    setup() {
        const router = useRouter()
        const companyStore = useCompanyStore()
        const { client, hydratePagination } = usePb()
        const { hydrateInvoice } = invoiceHydrator()

        const loading = ref(true)
        const items = ref<TInvoiceHydrated[]>([])
        const pagination = ref<TPagination>()
        const page = computed(() => router.currentRoute.value.params.page)

        const load = async () => {

            const resInvoices = await client.collection('invoice').getList<TInvoice>(page.value, 15, {
                filter: `company='${companyStore.current?.id}'`,
                sort: '-invoiceNumber',
                expand: 'customer'
            })

            pagination.value = hydratePagination(resInvoices)
            items.value = resInvoices.items.map((invoice: TInvoice) => hydrateInvoice(invoice))

            loading.value = false
        }

        const handleAddInvoice = () => {
            router.push({ name: 'InvoiceAdd' })
        }

        watch(page, () => load())
        onMounted(() => load())

        return { loading, items, pagination, handleAddInvoice }
    }
})
