
import { computed, defineComponent, PropType } from 'vue'
import { TPagination } from '@/types/TPagination'
import { useRouter } from 'vue-router'

export default defineComponent({
    name: 'Pagination',
    props: {
        routeName: String,
        pagination: Object as PropType<TPagination>
    },
    setup(props) {
        const router = useRouter()
        const current = computed(() => ! props.pagination?.page ? 1 : props.pagination.page)
        // TODO: Fix pagination - show only max 5 links
        const pgLoops = computed(() => (current.value - 3) + (current.value + 3))

        const move = (steps: number) => {
            router.push({ name: props.routeName, params: { page: current.value + steps } })
        }

        return { move, pgLoops }
    }
})
