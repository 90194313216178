
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { usePb } from '@/composables/usePb'
import SideBar from '@/components/SideBar.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import { TCompany } from '@/types/TCompany'
import CompanyForm from '@/components/forms/CompanyForm.vue'

export default defineComponent({
    name: 'ContactEdit',
    components: { CompanyForm, SideBar, LoadingOverlay },
    setup() {
        const router = useRouter()
        const { client } = usePb()

        const company = ref<TCompany>()
        const loading = ref(true)

        const id = router.currentRoute.value.params.id

        onMounted(async () => {
            try {
                company.value = await client.collection('company').getOne(id , { expand: 'contact' })
            } catch (e) {
                console.error(e)
            }

            loading.value = false
        })

        return { loading, company }
    }
})
