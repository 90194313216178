
import { defineComponent, PropType } from 'vue'
import { TContact } from '@/types/TContact'

export default defineComponent({
    name: 'Supplier',
    props: {
        supplier: Object as PropType<TContact>,
    }
})
