import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)), ["prevent"])),
      class: _normalizeClass(["dropdown-item", _ctx.classes]),
      href: "#"
    }, _toDisplayString(_ctx.name), 3)
  ]))
}