import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  class: "position-absolute rounded-3 border bg-danger overflow-auto shadow",
  style: {"width":"300px","max-height":"150px","z-index":"2"}
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.labelWrapper)
    }, [
      _createElementVNode("label", {
        for: `${_ctx.name}-autocomplete`,
        class: "form-label mb-1"
      }, _toDisplayString(_ctx.label), 9, _hoisted_1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.inputWrapper)
    }, [
      _createVNode(_component_Field, {
        name: _ctx.name,
        type: "hidden",
        modelValue: _ctx.selectedId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedId) = $event))
      }, null, 8, ["name", "modelValue"]),
      _createElementVNode("div", {
        class: _normalizeClass(["input-group", { 'is-invalid' : _ctx.errors[_ctx.name] }])
      }, [
        _createVNode(_component_Field, {
          modelValue: _ctx.inputName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputName) = $event)),
          class: _normalizeClass([{ 'is-invalid' : _ctx.errors[_ctx.name] }, "form-control"]),
          id: `${_ctx.name}-autocomplete`,
          name: `${_ctx.name}Autocomplete`,
          onKeyup: _ctx.onSearch,
          placeholder: "Začněte psát...",
          autocomplete: "off"
        }, null, 8, ["modelValue", "class", "id", "name", "onKeyup"]),
        _renderSlot(_ctx.$slots, "button-group")
      ], 2),
      (_ctx.items.length !== 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleHide && _ctx.handleHide(...args))),
            class: "position-relative"
          }, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createElementBlock("button", {
                  onClick: ($event: any) => (_ctx.onSelect(item)),
                  class: "btn btn-light rounded-0 px-3 py-2 w-100 border-0 text-start border-bottom"
                }, _toDisplayString(item.name), 9, _hoisted_3))
              }), 256))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.errors[_ctx.name]), 1)
    ], 2)
  ], 64))
}