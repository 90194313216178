import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  class: "backdrop",
  style: {"z-index":"1000"}
}
const _hoisted_2 = { class: "modal-dialog" }
const _hoisted_3 = { class: "modal-content px-3" }
const _hoisted_4 = { class: "h5 pt-4 mb-0" }
const _hoisted_5 = { class: "mb-0 pt-3 pb-4" }
const _hoisted_6 = {
  key: 0,
  class: "pb-4 text-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!
  const _component_SubmitButtonSm = _resolveComponent("SubmitButtonSm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "modal fade show d-block",
      tabindex: "-1",
      onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)), ["self"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createTextVNode("...")
            ])
          ]),
          _renderSlot(_ctx.$slots, "alert", {}, () => [
            _createVNode(_component_Alert, {
              data: _ctx.alert,
              class: "mb-4"
            }, null, 8, ["data"])
          ]),
          _renderSlot(_ctx.$slots, "links", {}, () => [
            (_ctx.btnAccept)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("button", {
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args))),
                    class: "btn btn-outline-secondary me-3"
                  }, "Zavřít"),
                  _createVNode(_component_SubmitButtonSm, {
                    onClick: _ctx.handleAccept,
                    loading: _ctx.acceptLoading,
                    name: _ctx.btnAccept,
                    type: "btn-danger"
                  }, null, 8, ["onClick", "loading", "name"])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}