
import { defineComponent, PropType } from 'vue'
import SubmitButtonSm from '@/components/buttons/SubmitButtonSm.vue'
import Alert from "@/components/Alert.vue"
import { TAlert } from '@/types/TAlert'

export default defineComponent({
    name: 'Modal',
    components: { SubmitButtonSm, Alert },
    props: {
        title: String,
        btnAccept: String,
        acceptLoading: Boolean,
        alert: Object as PropType<TAlert | undefined>
    },
    setup(props, { emit }) {
        const closeModal = () => emit('close')
        const handleAccept = () => emit('accept')

        return { closeModal, handleAccept }
    }
})

