
import { defineComponent, onMounted, ref } from 'vue'
import SideBar from '@/components/SideBar.vue'
import PageTitle from '@/components/PageTitle.vue'
import ContactForm from '@/components/forms/ContactForm.vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import { usePb } from '@/composables/usePb'
import { TContact } from '@/types/TContact'

export default defineComponent({
    name: 'ContactDetail',
    components: { LoadingOverlay, ContactForm, PageTitle, SideBar },
    setup() {
        const router = useRouter()
        const { client } = usePb()

        const contact = ref<TContact>()
        const loading = ref(true)

        onMounted(async () => {
            contact.value = await client.collection('contact').getOne(router.currentRoute.value.params.id)
            loading.value = false
        })

        return { loading, contact }
    }
})
