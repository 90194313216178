
import { defineComponent, onMounted, ref } from 'vue'
import { Form, Field } from 'vee-validate'
import { usePb } from '@/composables/usePb'
import { TCompany } from '@/types/TCompany'
import SideBar from '@/components/SideBar.vue'
import Dropdown from '@/components/dropdown/Dropdown.vue'
import DropdownItem from '@/components/dropdown/DropdownItem.vue'
import LoadingOverlay from '@/components/LoadingOverlay.vue'
import Modal from '@/components/modal/Modal.vue'
import Company from '@/components/Company.vue'
import SubmitButtonSm from '@/components/buttons/SubmitButtonSm.vue'
import Autocomplete from '@/components/autocomplete/Autocomplete.vue'
import Alert from '@/components/Alert.vue'
import { TAlert } from '@/types/TAlert'
import { useCompanyStore } from '@/components/store/companyStore'
import { storeToRefs } from 'pinia'

type Values = {
    contact: string
}

export default defineComponent({
    name: 'Companies',
    components: {
        Alert,
        Autocomplete,
        Form,
        Field,
        SubmitButtonSm,
        Company,
        Modal,
        LoadingOverlay,
        DropdownItem,
        Dropdown,
        SideBar
    },
    setup() {
        const schema = { contact: 'required' }

        const { client } = usePb()
        const companyStore = useCompanyStore()
        const { companies } = storeToRefs(companyStore)

        const loading = ref(false)
        const modalAddOpened = ref(false)
        const modalBtnLoading = ref(false)
        const alert = ref<TAlert>()

        const modalAddHandleOpen = () => {
            modalAddOpened.value = true
            alert.value = undefined
        }

        const searchHandler = async (name: string) => {
            alert.value = undefined
            try {
                const resp = await client.collection('contact').getList(1, 10, {
                    filter: `name~'${name}'`
                })
                return resp.items
            } catch (e) {
            }

            return []
        }

        const onRemoved = async (id: string) => {
            companies.value = companies.value.filter(item => item.id !== id)
            if (companyStore.current?.id === id) {
                const primary = companies.value.filter(item => item.primary).shift() as TCompany
                companyStore.setCurrent(primary)
            }
        }

        const onChangedPrimary = async (company: TCompany) => {
            const current = companies.value.filter(item => item.primary).shift()

            if (current?.id) {
                await client.collection('company').update(current.id, { primary: false })
            }

            companies.value = companies.value.map(item => {
                return { ...item, primary: item.id === company.id }
            })
        }

        const onSubmit = async (values: Values) => {
            modalBtnLoading.value = true
            try {
                const resp = await client.collection('company').create({ contact: values.contact }, {
                    expand: 'contact'
                })
                companies.value.push(resp)
                modalAddOpened.value = false
            } catch (e) {
                alert.value = { type: 'alert-danger', message: 'Tuto firmu již máte přidanou' }
            }

            modalBtnLoading.value = false
        }

        onMounted(async () => {
            loading.value = true

            if (companies.value.length === 0) {
                try {
                    const resp = await client.collection('company').getList(1, 400, { expand: 'contact' })
                    companyStore.setCompanies(resp.items)
                } catch (e) {

                }
            }

            loading.value = false
        })

        return {
            loading,
            companies,
            schema,
            onSubmit,
            onRemoved,
            onChangedPrimary,
            modalAddOpened,
            modalBtnLoading,
            modalAddHandleOpen,
            searchHandler,
            alert
        }
    }
})
