
import { defineComponent, onMounted, ref, watch } from 'vue'
import { usePb } from '@/composables/usePb'
import { useRouter } from 'vue-router'
import { useCompanyStore } from '@/components/store/companyStore'
import { storeToRefs } from 'pinia'

export default defineComponent({
    name: 'ChangeCompany',
    components: {},
    setup() {
        const router = useRouter()
        const companyStore = useCompanyStore()
        const { current } = storeToRefs(companyStore)
        const input = ref(current.value?.id)

        const { client } = usePb()
        const { companies } = storeToRefs(companyStore)
        const loading = ref(false)

        const handleChange = async () => {
            loading.value = true
            try {
                const resp = await client.collection('company').getOne(input.value, {
                    expand: 'contact'
                })
                companyStore.setCurrent(resp)
                await router.push({ name: 'Invoices', params: { page: 1 }, force: true})
                router.go(0)
            } catch (e) {
                console.error(e)
                loading.value = false
            }
        }

        onMounted(async () => {
            if (companyStore.companies.length === 0) {
                try {
                    const resp = await client.collection('company').getList( 1, 400, {
                        expand: 'contact'
                    })
                    companyStore.setCompanies(resp.items)
                } catch (e) {
                    console.error(e)
                }
            }
        })

        watch(current, () => {
            input.value = current.value?.id
        })

        return { handleChange, input, companies, loading }
    }
})
